<template>
  <div>
    <Nav />

    <Tittle class="bg-dark">
      <h1 class="fadeInUp"><span></span>Faslearn blog</h1>
    </Tittle>

    <div class="container margin_60_35">
      <div class="row">
        <div class="col-lg-9">
          <div class="bloglist singlepost">
            <p v-if="post.imageFile">
              <img
                alt=""
                class="img-fluid"
                :src="publicFiles+post.imageFile"
              />
            </p>
            <h1>{{post.title}}</h1>
            <div class="postmeta">
              <ul>
                <li>
                  <a href="#"><i class="icon_folder-alt"></i> Collections</a>
                </li>
                <li>
                  <a href="#"><i class="icon_clock_alt"></i> {{post.createdAt}}</a>
                </li>
                <li>
                  <a href="#"><i class="icon_pencil-edit" v-if="post.superAdmin"></i> {{post.superAdmin[0].name}}</a>
                </li>
                <li>
                  <a href="#"><i class="icon_comment_alt"></i> ({{comments.length}}) Comments</a>
                </li>
              </ul>
            </div>
            <div class="post-content">
              <div class="dropcaps">
                <p v-for="c in post.contents" :key="c">
                  <img 
                    class="img-fluid"
                    :src="publicFiles+c.file" 
                    alt="" 
                    v-if="c.file"
                  >
                  <span v-html="formatText(c.text)"></span>
                </p>
              </div>
            </div>
          </div>
          <br><br><br>
          <div id="comments">
            <h5>Comments</h5>
            <ul >
              <li v-for="c in comments" :key="c">
                <div class="avatar">
                  <a href="#" >
          
                    <ProfileImage style="width: 50px; height: 50px; border-radius: 50%" :id="c.user[0]._id" :name="c.user[0].imageFile"  v-if="user!=''"/>
                  </a>
                </div>
                <div class="comment_right clearfix">
                  <div class="comment_info">
                    By <a href="#">{{c.user[0].firstName}} {{c.user[0].lastName}}</a><span>|</span>{{c.createdAt}}<span
                      >|</span
                    >
                    <p>{{c.content}}</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <hr />

          <h5>Leave a Comment </h5>
          <form v-if="userId!=null&&userId!=undefined&&userId!=''">
            <div class="form-group">
              <textarea
                class="form-control"
                name="comments"
                id="comments2"
                rows="6"
                v-model="text"
                placeholder="Message Below"
              ></textarea>
            </div>
            <div class="form-group">
              <button
                type="submit"
                id="submit2"
                @click.prevent="addComment()"
                v-if="!Loading"
                class="btn_1 rounded add_bottom_30"
              >
                Submit
              </button>
              <button
                type="submit"
                id="submit2"
                v-if="Loading"
                class="btn_1 rounded add_bottom_30"
              >
                Please Wait ...
              </button>
            </div>
          </form>
          <form v-else @click="goLogin">
            <div class="form-group">
              <textarea
                class="form-control"
                name="comments"
                id="comments2"
                rows="6"
                placeholder="Message Below"
              ></textarea>
            </div>
            <div class="form-group">
              <button
                type="submit"
                id="submit2"
                class="btn_1 rounded add_bottom_30"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
        <!-- /col -->

        <aside class="col-lg-3">
          <!-- <div class="widget">
            <form>
              <div class="form-group">
                <input
                  type="text"
                  name="search"
                  id="search"
                  class="form-control"
                  placeholder="Search..."
                />
              </div>
              <button type="submit" id="submit" class="btn_1 rounded">
                Search
              </button>
            </form>
          </div> -->
          <!-- /widget -->
          <RightSideBar />
          <!-- /widget -->
        </aside>
        <!-- /aside -->
      </div>
      <!-- /row -->
    </div>
    <!-- /container -->
    <Footer />
  </div>
</template>

<script>
import Nav from "@/components/site/Nav.vue";
import RightSideBar from "@/components/site/RightSideBar.vue";
import Tittle from "@/components/site/Tittle.vue";
import Footer from "@/components/site/Footer.vue";
import { mapActions, mapGetters } from 'vuex';
import ProfileImage from "@/components/ProfileImage";
export default {
  name: "Blogpost",
  data() {
    return {
      userId: localStorage.getItem('fmruser'),
      postId: "",
      post: {superAdmin: [{name: ""}],contents: []},
      limit: 10, 
      page: 1,
      title: "",
      text: "",
      Loading: false,
      Success: false,
      thumbnail: '',
      errors: [],
      comments: []
    };
  },
  components: {
    Nav,
    Tittle,
    RightSideBar,
    Footer,
    ProfileImage
  },
  computed: {
    ...mapGetters(['publicFiles'])
  },
  methods: {
    ...mapActions(["fetchBlogPosts__public", "fetchBlogPost__public", "fetchBlogPostComment__public", "createBlogPostComment"]),
    addComment(){
      this.Loading = true
      let object = {
        blogPostId: this.postId, 
        content: this.text, 
        userId: this.userId
      }
      this.createBlogPostComment(object)
      .then(res => {
        this.Loading = false
        this.comments.push(res.data.blogPostComment)
        console.log(res.data.blogPostComment)
        console.log(this.comments)
      })
    },
    formatText(text) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(text, 'text/html');
      return doc.body.innerHTML;
    },
    goLogin(){
      localStorage.setItem('fmrlandingpage', window.location.href)
      let str = window.location.href+"";
      const myArr = str.split("/blog");
      window.location.href = myArr[0] + "/login"
    }
  },
  mounted(){
    this.postId = this.$route.params.id
    this.fetchBlogPost__public(this.postId)
    .then(res => {
      this.post = res.data.blogPost
    })
    this.fetchBlogPostComment__public({id: this.postId, limit: this.limit, page: this.page})
    .then(res => {
      this.comments = res.data.blogPostComments
      this.total = res.data.total
    })
  }
};
</script>

<style>
  @import url("./assets/css/blog.css");
  @import url("./assets/css/custom.css");
</style>